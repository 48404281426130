import {
    Avatar,
    Container,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useAuthentication } from '@zmvp/app'
import { useValidation } from '@zmvp/forms'
import {
    BackdropProgress,
    ProgressButton,
    useErrorHandler
} from '@zmvp/material-ui'
import { useAxiosRequest } from '@zmvp/app'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { useRouter } from 'next/router'
import React from 'react'
import { useMutation } from 'react-query'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%'
    }
}))

export default function LoginFactory({ redirectOnSuccess = '/' }) {
    return function Login() {
        const router = useRouter()
        const login = useAxiosRequest({
            method: 'post',
            url: '/api/authentication'
        })

        const {
            isAuthenticated,
            isReady,
            authenticate,
            user,
            hasRole
        } = useAuthentication()
        const { handleError } = useErrorHandler()

        const [values, setValues] = React.useState({
            email: '',
            password: ''
        })
        const [isError, setIsError] = React.useState(false)

        const { validate, errors, ifError, reset } = useValidation({
            email: ['required', 'email'],
            password: ['required']
        })

        const {
            mutateAsync: loginAsync,
            isLoading,
            reset: resetLoginAsync
        } = useMutation(login, {
            onSuccess: authenticate
        })

        const onChange = React.useCallback(
            e => {
                setValues({
                    ...values,
                    [e.target.name]: e.target.value
                })

                reset()
                resetLoginAsync()
            },
            [values]
        )

        const onSubmit = React.useCallback(
            async e => {
                e.preventDefault()

                if (!validate(values)) {
                    return
                }

                try {
                    await loginAsync({ data: values })
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        setIsError(true)
                        return
                    }

                    handleError(error)
                }
            },
            [values]
        )

        React.useEffect(() => {
            if (!isAuthenticated) {
                return
            }

            const { redirect } = router.query

            if (redirect) {
                router.push(redirect)
                return
            }

            if (isString(redirectOnSuccess)) {
                router.push(redirectOnSuccess)
            } else if(isFunction(redirectOnSuccess)) {
                redirectOnSuccess({
                    user,
                    hasRole,
                    router
                })
            }
        }, [isAuthenticated, router.query])

        const classes = useStyles()

        if (!isReady) {
            return <BackdropProgress />
        }

        return (
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" gutterBottom>
                        Login
                    </Typography>
                    {isError && (
                        <Alert severity="error">
                            Invalid email or password
                        </Alert>
                    )}
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={values.email}
                            onChange={onChange}
                            error={errors.email}
                            helperText={ifError(
                                'email',
                                'Please fill in your email'
                            )}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            values={values.password}
                            onChange={onChange}
                            error={errors.password}
                            helperText={ifError(
                                'password',
                                'Please fill in your password'
                            )}
                        />
                        <ProgressButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            isLoading={isLoading}
                        >
                            Login
                        </ProgressButton>
                    </form>
                </div>
            </Container>
        )
    }
}
